import Image from "next/image";
import React, { useState } from "react";
import splitImg from "../../public/images/split/real-estate-img.png";
import jarvisTemplate from "../../public/images/split/sims-building.png";
import Link from "next/link";
import DownloadPDF from "../Common/DownloadPdf";

const Split = ({ type, id }) => {
  const [isSeeMore, setIsSeeMore] = useState(false);
  return (
    <div id={id}>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={type === "Jarvis" ? jarvisTemplate : splitImg}
                      alt="split Images"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      {type === "Jarvis"
                        ? "JARVIS Platform"
                        : "About JARVIS Real Estate Template"}
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      JARVIS offers comprehensive solutions tailored to diverse
                      industries, supported by its versatile templates and
                      intuitive interface. With adaptable hardware options and
                      proven deployment strategies, our platform facilitates
                      seamless reporting and analysis on the cloud.
                    </p>
                    {type === "Jarvis" ? (
                      <div className="explore-jarvis">
                        <div className="jarvis-see-more">
                          <button
                            onClick={() => setIsSeeMore(!isSeeMore)}
                            className={`btn-default btn-small round`}
                          >
                            {!isSeeMore ? "See More" : "See Less"}
                          </button>
                        </div>
                        <DownloadPDF
                          name={"JARVIS.pdf"}
                          urlPath={"/pdf/2024-JARVIS.pdf"}
                        />
                      </div>
                    ) : null}
                    {type === "Jarvis" ? (
                      <></>
                    ) : (
                      // <ul
                      //   className="split-list"
                      //   data-sal="slide-up"
                      //   data-sal-duration="400"
                      //   data-sal-delay="350"
                      // >
                      //   <li>
                      //     - Multiple templates which includes Government
                      //     template and Real estate template for Energy
                      //     Monitoring, Environment, Visitor management and more.
                      //   </li>
                      //   <li>
                      //     - With yearly VAPT assessment for any vulnerability
                      //   </li>
                      // </ul>
                      <p
                        className="description"
                        data-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="300"
                      >
                        Jarvis offers a comprehensive suite of solutions
                        tailored to various needs, particularly focused on
                        sensory monitoring for accident prevention, data
                        oversight for analysis, trend monitoring, and AI
                        prediction. This diversity in templates indicates a
                        versatile approach to addressing different scenarios and
                        requirements, ensuring robust monitoring and predictive
                        capabilities across various domains.
                      </p>
                    )}

                    {/* <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <Link
                        className="btn-default"
                        href="https://jarvis.viatick.com/app/user/login"
                      >
                        JARVIS Platform
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row g-0 align-items-center m--20">
                <div className="col-12">
                  {isSeeMore ? (
                    <div className="mt--20 jarvis-content-wrapper">
                      <h6
                        ata-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="300"
                      >
                        Key Features
                      </h6>
                      <ol className="">
                        <li>
                          <span>
                            <strong>Industry-Specific Templates:</strong> JARVIS
                            provides pre-designed templates tailored to various
                            industries, streamlining processes and addressing
                            specific needs efficiently.
                          </span>
                        </li>
                        <li>
                          <strong>Versatile Views:</strong> Our platform offers
                          customizable views to accommodate different workflows
                          and preferences, ensuring ease of use across diverse
                          sectors.
                        </li>
                        <li>
                          <strong>Hardware Options:</strong> Choose from a range
                          of hardware options that have been rigorously tested
                          and proven effective across multiple deployments,
                          ensuring reliability and performance.
                        </li>
                        <li>
                          <strong>Cloud-Based Reporting and Analysis:</strong>{" "}
                          JARVIS enables real-time reporting and analysis on the
                          cloud, allowing for informed decision-making and agile
                          responses to market dynamics.
                        </li>
                      </ol>
                      <h6
                        ata-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="300"
                      >
                        Benefits
                      </h6>
                      <ol className="">
                        <li>
                          <span>
                            <strong>Enhanced Efficiency:</strong> By leveraging
                            industry-specific templates and intuitive views,
                            organizations can optimize workflows and boost
                            productivity.
                          </span>
                        </li>
                        <li>
                          <strong>Versatile Views:</strong> Our platform offers
                          customizable views to accommodate different workflows
                          and preferences, ensuring ease of use across diverse
                          sectors.
                        </li>
                        <li>
                          <strong>Scalability:</strong> JARVIS offers scalable
                          solutions that can grow with your business, adapting
                          to evolving requirements and expanding operations
                          seamlessly.
                        </li>
                        <li>
                          <strong>Reliability:</strong> With proven hardware
                          options and robust deployment strategies, our platform
                          ensures reliability and stability in mission-critical
                          environments.
                        </li>
                        <li>
                          <strong>Insightful Analytics:</strong> With proven
                          hardware options and robust deployment strategies, our
                          platform ensures reliability and stability in
                          mission-critical environments.
                        </li>
                      </ol>
                      <h6
                        ata-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="300"
                      >
                        Conclusion
                      </h6>
                      <p>
                        JARVIS empowers organizations across diverse industries
                        with its versatile templates, customizable views, and
                        reliable hardware options. By harnessing the power of
                        cloud-based reporting and analysis, our platform equips
                        businesses with the tools they need to thrive in today’s
                        dynamic marketplace.
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Split;
