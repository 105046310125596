const DownloadPDF = ({ urlPath }) => {

  return (
    <button className={`btn-default btn-small round`}>
      <a className="text-white" href={urlPath} download>Download</a>
    </button>
  );
};

export default DownloadPDF;
