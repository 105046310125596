import Link from "next/link";
import React, { useEffect } from "react";
import Image from "next/image";

const ServiceItem = ({ ServiceData }) => {
  return (
    <>
      {ServiceData &&
        ServiceData.service.map((data, index) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
            <div className="service service__style--1 bg-color-light radius mt--25 text-center rbt-border-none variation-4 bg-flashlight">
              <div className="img-bar">
                <Image
                  src={data.image}
                  width={50}
                  height={50}
                  alt="AI Generator"
                />
              </div>
              <div className="content">
                <h4 className="title w-600">{data.title}</h4>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default ServiceItem;
