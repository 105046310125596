import React, { useEffect } from "react";

import ServiceItem from "./ServiceItem";
import ServiceData from "../../data/home.json";

const Service = () => {
  return (
    <>
      <div className="rainbow-service-area rainbow-section-gap">
        <div className="container">
          <div className="row row--15 service-wrapper">
            <ServiceItem ServiceData={ServiceData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
