import React, { useEffect, useState } from "react";
import sal from "sal.js";
import Slider from "react-slick";

import "venobox/dist/venobox.min.css";
import Link from "next/link";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { URL, URL_SERVER } from "@/constants";
import axios from "axios";
import newsData from "../../data/news.json";
import LazyLoadComponent from "../Common/LazyLoadComponent";
import ErrorPage from "@/pages/_error";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#0e76c6",
        borderRadius: "50%",
        marginLeft: "40px",
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#0e76c6",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const Timeline = () => {
  const [newsIds, setNewsIds] = useState([]);
  const [whereClause, setWhereClause] = useState({
    attributes: ["id"],
    limit: 5,
    offset: 0,
  });
  // useEffect(() => {
  //   sal();

  //   import("venobox/dist/venobox.min.js").then((venobox) => {
  //     new venobox.default({
  //       selector: ".popup-video",
  //       maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
  //     });
  //   });
  // }, []);

  const getNewsData = async (input) => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.baseURL = URL_SERVER;

    try {
      const response = await axios.post(URL.FIND_ALL_NEW, input);
      if (response.status === 200) {
        if (response.data.data) {
          setNewsIds(response.data.data?.rows.map((item) => item.id));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewsData(whereClause);
  }, [whereClause]);

  const NewsContent = ({ id, index }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
      getNewsById();
    }, []);

    const getNewsById = async () => {
      const response = await axios.post(URL.FIND_ALL_NEW, {
        where: { id: id },
      });
      if (response.status === 200) {
        if (response.data.data) {
          setData(response.data.data?.rows[0]);
        }
      }
    };

    return (
      <div className="news-card-item" key={index}>
        <div className="news-item-wrapper card shadow-light">
          <div className="news-desc-container">
            <h5>{data.title}</h5>
            <p className="news-item-desc">{data.abstract}</p>
          </div>
          <div className="news-see-more">
            <Link className={`btn-default btn-small round`} href={"/news"}>
              See More
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const slickSettings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
    ],
  };
  return (
    <div id="about">
      <div className="rainbow-timeline-area rainbow-section-gap">
        <div className="container m--40">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">About Viatick</span>
                </h4>
                <h3 className="title w-600 mb--20">
                  VIATICK Pte. Ltd. is an aIoT company that specializes in
                  Artificial Intelligence through IoT. Our vision is to enable
                  companies to use their own data to perform decisions that can
                  make them more money, save more money, or discover insights
                  that do both.
                </h3>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12 mt--30">
              <div className="timeline-style-two bg-color-light">
                <div className="row row--0">
                  {TimelineData &&
                    TimnelineData.timeline.map((data, index) => (
                      <div
                        className="col-lg-4 col-md-4 rainbow-timeline-single dark-line"
                        key={index}
                      >
                        <div className="rainbow-timeline">
                          <h6
                            className="title"
                            data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="200"
                          >
                            {data.title}
                          </h6>
                          <div className="progress-line">
                            <div className="line-inner"></div>
                          </div>
                          <div className="progress-dot">
                            <div className="dot-level">
                              <div className="dot-inner"></div>
                            </div>
                          </div>
                          <p
                            className="description"
                            data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="300"
                          >
                            {data.desc}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div> */}
          {newsIds.length > 0 ? (
            <div className="mt--20 mb--20">
              <Slider {...slickSettings}>
                {newsIds.map((data, index) => (
                  <LazyLoadComponent key={index}>
                    <NewsContent id={data} index={index} />
                  </LazyLoadComponent>
                ))}
                {/* {newsData &&
                newsData.news.map((data, index) => (
                  <div className="news-card-item" key={index}>
                    <div className="news-item-wrapper card shadow-light">
                      <h5>{data.title}</h5>
                      <p className="news-item-desc">{data.desc}</p>
                      <div className="news-see-more">
                        <Link
                          className={`btn-default btn-small round`}
                          href={"/news"}
                        >
                          See More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))} */}
              </Slider>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
