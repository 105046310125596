import React, { useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";

import "venobox/dist/venobox.min.css";

import bannerImg from "../../public/images/banner/banner-image-03.png";
import separator from "../../public/images/separator/separator-top.svg";

const Home = () => {

  useEffect(() => {
    document.getElementById('home-video').play();
  }, []);

  // useEffect(() => {
  //   import("venobox/dist/venobox.min.js").then((venobox) => {
  //     new venobox.default({
  //       selector: ".popup-video",
  //       maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
  //     });
  //   });
  // }, []);
  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container mb--10">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--60">
                <h1 className="title display-one">
                  Unleash Your Full Potential <br />
                  With The <span className="theme-gradient">JARVIS</span>{" "}
                  Platform{" "}
                </h1>
                <p className="b1 desc-text">
                  Revolutionizing business activities with AIoT
                </p>
                <div className="button-group">
                  <Link className="btn-default btn-large" href="/contact-us">
                    <div className="has-bg-light"></div>
                    <span>Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-xl-10 order-1 order-lg-2 mt--20">
              <img src="/videos/thumb.png" alt="Video Thumbnail"  style={{ display: 'none' }}/>
              <video autoplay muted controls height="auto" id="home-video">
                <source src="/videos/home-video.mp4" type="video/mp4" />
              </video>
              {/* <div className="frame-image frame-image-bottom bg-flashlight video-popup icon-center">
                <Image src={bannerImg} alt="Banner Images" />
                <div className="video-icon">
                  <Link
                    className="btn-default rounded-player popup-video border bg-white-dropshadow"
                    href="https://youtu.be/ThRYF96HIzA?si=Yz-Yc5HSf8uLPv-G"
                    data-vbtype="video"
                  >
                    <span>
                      <i className="feather-play"></i>
                    </span>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="chatenai-separator">
          <Image className="w-100" src={separator} alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div
            className="section-title text-center"
            data-sal="slide-up"
            data-sal-duration="700"
            data-sal-delay="100"
          >
            <h4 className="subtitle">
              <span className="theme-gradient">
                Improve your processes with JARVIS
              </span>
            </h4>
            <h2 className="title w-600 mb--20">
              Get Insight Analytics With Predictive AI
            </h2>
            <p className="description b1">
              With Many Different Templates To Suit Your Organisation Needs
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
