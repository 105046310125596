import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Home from "@/components/Home/Home";
import Service from "@/components/Service/Service";
import Context from "@/context/Context";
import Separator from "../separator";
import Timeline from "@/components/Timeline/Timeline";
import Split from "@/components/Split/Split";
import Split2 from "@/components/Split2/Split";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
import Partners from "@/components/Partners/Partners";
import CallToAction from "@/components/CallToAction/CallToAction";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";
import PageHead from "../Head";
import TemplateData from "../../data/home.json";
import Head from "next/head";
import { URL, URL_SERVER } from "@/constants";
import ErrorPage from "../_error";

// export async function getServerSideProps() {
//   axios.defaults.headers.post["Content-Type"] = "application/json";
//   axios.defaults.baseURL = URL_SERVER;

//   try {
//     const input = {
//       attributes: ["id"],
//       limit: 5,
//       offset: 0,
//     };
//     const response = await axios.post(URL.FIND_ALL_NEW, input);
//     let data = [];
//     if (response.status === 200) {
//       if (response.data.data) {
//         data = response.data.data?.rows.map((item) => item.id);
//       }
//     }
//     return { props: { newsIds: data }, revalidate: 60 };
//   } catch (error) {
//     return { props: { error: { statusCode: response.status } } };
//   }
// }

const HomePage = () => {
  // return error ? (
  //   <ErrorPage statusCode={error.statusCode} />
  // ) : (
  return (
    <>
      <PageHead
        title="Home"
        description={
          "VIATICK Pte. Ltd. is an aIoT company that specializes in Artificial Intelligence through IoT."
        }
      />

      <main className="page-wrapper">
        <Context>
          {/* <HeaderTop /> */}
          <Header
            headerTransparent="header-not-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
          />
          <PopupMobileMenu />

          <Home />
          <Split type={"Jarvis"} id={"jarvis"} />
          <Separator top={false} />
          <Service />
          <Separator top={true} />
          <Split2 id={"gov"} />
          <Separator top={true} />
          <Split id={"real-estate"} />
          <Separator top={false} />

          {/* <Pricing /> */}
          {/* <Separator top={true} /> */}
          <Timeline />
          <Separator top={false} />
          <Accordion isHead={true} />
          <Separator top={false} />
          <Partners />
          <Separator top={true} />
          <CallToAction />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;
