import Image from "next/image";

import govTemplate from "../../public/images/split/gov-template.png";
import Link from "next/link";

const Split = () => {
  return (
    <>
      <div className="rainbow-split-area rainbow-section-gap">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      About JARVIS Government Template
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >
                      Jarvis provides a robust solution centered around sensory
                      monitoring to prevent accidents and oversee incoming data
                      for further analysis. It offers capabilities to monitor
                      trends and utilize AI prediction, enhancing its ability to
                      anticipate and respond to potential issues proactively. By
                      integrating advanced monitoring technologies with data
                      analysis and prediction algorithms, Jarvis empowers users
                      to make informed decisions and take preventive actions,
                      thereby enhancing safety and efficiency across various
                      domains.Multiple usage for Government template :
                    </p>
                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <li>-Energy Monitoring</li>
                      <li>-Environment Monitoring</li>
                      <li>-ESG reporting and more</li>
                      <p
                        className="description"
                        data-sal="slide-up"
                        data-sal-duration="400"
                        data-sal-delay="300"
                      >
                        With yearly VAPT assessment for any vulnerability and
                        Consistent updates for performance and stability.
                      </p>
                    </ul>
                    {/* <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    >
                      <Link
                        className="btn-default"
                        href="https://jarvis.viatick.com/app/user/login"
                      >
                        JARVIS Platform
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="thumbnail">
                    <Image
                      className="radius"
                      src={govTemplate}
                      alt="government template"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Split;
