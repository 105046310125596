import React, { useState, useEffect } from "react";
import Image from "next/image";

import PartnerData from "../../data/home.json";
import mainLogos from "../../public/images/partners/main-icons.png";
import expandLogos from "../../public/images/partners/see-more-icons.png";

const Partners = () => {
  const [seeMore, setSeeMore] = useState(true);
  const [limit, setLimit] = useState(39);
  const [mainData, setMainData] = useState([]);
  const [seeMoreData, setSeeMoreData] = useState([]);

  useEffect(() => {
    const data1 = PartnerData?.partners.slice(0, 9);
    const data2 = PartnerData?.partners.slice(9, 40);
    setMainData(data1);
    setSeeMoreData(data2);
    // setLimit(limit - 28);
  }, []);

  const clickMore = () => {
    setSeeMore(!seeMore);
    if (seeMore) setLimit(limit + 21);
    else setLimit(limit - 21);
  };

  return (
    <>
      <div className="rainbow-brand-area rainbow-section-gap">
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center sal-animate"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">Our Partners</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row mt--10 mb--10">
            <div className="col-lg-12 mt--30">
              <ul className="brand-list brand-style-2">
                {PartnerData &&
                  PartnerData.partners.slice(0, limit).map((data, index) => (
                    <li key={index}>
                      <a href="#">
                        <Image
                          src={data.image}
                          width={100}
                          height={70}
                          alt="Parnter Image"
                        />
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="btn-see-more">
            <button
              className={`btn-default btn-small round`}
              onClick={clickMore}
            >
              {seeMore ? "See More" : "See Less"}
            </button>
          </div>
        </div> */}
        <div className="container partners-wrapper">
          <div className="row m--10">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="our-parters">
                <h2>Our Partners</h2>
                <p>
                  We collaborate with a diverse range of partners, including
                  local and global entities such as government organizations and
                  private corporations, to meet a wide variety of needs with
                  dedication and professionalism.
                </p>
                <div className="btn-see-more">
                  <button
                    className={`btn-default btn-small round`}
                    onClick={clickMore}
                  >
                    {seeMore ? "See More" : "See Less"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="main-logos-wrapper">
                <Image
                  src={mainLogos}
                  width={350}
                  height={400}
                  alt="main partners"
                />
                {/* <ul className="brand-list brand-style-2">
                  {mainData &&
                    mainData.map((data, index) => (
                      <li key={index}>
                        <a href="#">
                          <Image
                            src={data.image}
                            width={100}
                            height={70}
                            alt="Parnter Image"
                          />
                        </a>
                      </li>
                    ))}
                </ul> */}
              </div>
            </div>
          </div>
          {!seeMore ? (
            <div className="expand-logos-wrapper">
              <Image src={expandLogos} height={450} alt="expand partners" />
            </div>
          ) : null}
          {/* {!seeMore ? (
            <div className="more-logos-wrapper mt--20">
              <ul className="brand-list brand-style-2">
                {seeMoreData &&
                  seeMoreData.map((data, index) => (
                    <li key={index}>
                      <a href="#">
                        <Image
                          src={data.image}
                          width={100}
                          height={70}
                          alt="Parnter Image"
                        />
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ) : null} */}
        </div>
      </div>
    </>
  );
};

export default Partners;
